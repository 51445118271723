import 'bootstrap';
import './scss/app.scss';
import './scss/main.scss';
import './scss/main-responsive.scss';

import Swiper from 'swiper';
require('particles.js');

//Load section backgrounds
$(function () {
    var section = document.querySelectorAll('div[data-section-bg], section[data-section-bg]');

    for (var i = 0; i < section.length; i++) {
        (function (n) {
            var img = new Image();

            img.onload = imageFound;
            img.onerror = imageNotFound;
            img.src = section[n].getAttribute('data-section-bg');

            function imageFound() {
                section[n].style.backgroundImage = 'url(' + img.src + ')';
            }

            function imageNotFound() {
                section.style.backgroundColor = '#000000';
                section.style.opacity = '1';
            }
        }(i));
    }
});

//Duplicate button span for effect
$(function () {
    let btn = $('.btn');
    let btnText;
    let $this = $(this);

    btn.each(function () {
        $this = $(this);

        $this.each(function () {
            btnText = $(this).find('> span').text().split(' ');
        });

        duplicate(function () {
            $this.find('> span').contents().filter(function () {
                return this.nodeType === 3;
            }).remove();
        });
    });

    function duplicate(callback) {
        for (let i = 0; i < btnText.length; i++) {
            let btnSpan;

            btnSpan = $this.find('> span').append('<span />');

            $this.find('> span > span').eq(i).text(btnText[i]);
            $this.find('> span > span').eq(i).attr('data-text', btnText[i]);
        }
        callback();
    }
});

//Load single div backgrounds
$(function () {
    var image = document.querySelectorAll('img[data-bg="true"]');

    for (var i = 0; i < image.length; i++) {
        (function (n) {
            var img = new Image();

            img.onload = imageFound;
            img.onerror = imageNotFound;
            img.src = image[n].src;

            function imageFound() {
                image[n].parentNode.style.backgroundImage = 'url(' + img.src + ')';
            }

            function imageNotFound() {
                image[n].parentNode.style.backgroundColor = '#000000';
            }
        }(i));
    }
});

//Set background-color
$(function(){
    var item = $('div[data-bg-color]');

    item.each(function(){
        var el = $(this);

        el.css({
            backgroundColor: el.data('bg-color')
        });
    });
});

//Swiper - Testimonials
$(function(){
    var swiperWrapper = $('.swiper-testimonials-wrapper');

    if (swiperWrapper.length > 0) {
        var swiper = new Swiper('.swiper-testimonials', {
            slidesPerView: 1,
            effect: 'fade',
            pagination: {
                el: '.swiper-testimonials-wrapper .swiper-pagination',
                clickable: true,
                renderBullet: function (index, className) {
                    return '<span class="' + className + '">' + (index + 1) + '</span>';
                },
            },
        });
    }
});

//Particles JS
$(function(){
    particlesJS.load('particles-js', 'img/particles.json', function() {
        console.log('callback - particles.js config loaded');
    });
});

//Navigation
$(function () {
    const $navigation = $('.navbar-menu');
    const $body = $('body');
    const $trigger = $('[data-toggle="navbar-menu"]');

    let a, b;

    function toggleMenu(e) {
        e.preventDefault();

        if (!$body.hasClass('menu-is-open')) {
            clearTimeout(b);

            $body.addClass('menu-is-open');
            $navigation.css('display', 'block');

            a = setTimeout(function () {
                $navigation.addClass('menu-open');
            }, 50);
        } else {
            $body.removeClass('menu-is-open');
            $navigation.removeClass('menu-open');

            b = setTimeout(function () {
                $navigation.css('display', '');
            }, 400);
        }
    }

    $trigger.bind('click', toggleMenu);
});